@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
    margin: 0px;
    font-family: 'Roboto', sans-serif;
}



.boxDashboad {
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 10px;
    max-width: 100%;
    min-height: calc(100% - 20px);
    height: fit-content;
    position: relative;
}


.btnUpdate {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #eb4947;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.btnUpdate:hover{
    background-color: #c43e3e;
}
.btnPubli {
 
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #eb4947;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.btnPubli:hover{
    background-color: #c43e3e;
}

.mainDashboard {
    max-width: 80vw;
    margin: 0 auto;
    margin-top: 2%;
    width: 100%; 

}
.tituloDashboard {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 20px;
    color: #eb4947;
      
}

.mainHeader{
    background-color: #eb4947!important;
}

.container {  
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px 20px;
    grid-auto-flow: row;
  }
  
  .publicacionesAPP { grid-area: 2 / 1 / 3 / 3; }
  
  .PublicacionesJoomla { 
 }
  
  .update { grid-area: 1 / 2 / 2 / 3; }
  
  .toUpdate{
    background-color: rgb(21, 196, 137)!important;
  }

  .inputNotificar{
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 5px;
  }
  .inputNotificar2{
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 5px;
  }

  .enviarNotificar{
    background-color: #eb4947;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
   

  }
  textarea.resultatNotificacions {
    width: 80%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    resize: none;
  }



  .appMantinance{
    background-color: #eb4947;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  }
  .appMantinance h1{
    font-size: 16px;
    margin: 0px;
    font-weight: 400;

  }

  /* Estilos para la tabla */
.publicitats-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.publicitats-table th,
.publicitats-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.publicitats-table th {
  background-color: #f2f2f2;
}

/* Estilos para las filas impares */
.publicitatActual:nth-child(odd) {
  background-color: #f2f2f2;
}

/* Estilos para los campos de entrada de texto */
.inputNotificar {
  width: 50px; /* Ajusta el ancho según tus preferencias */
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.inputLink{
  width: 90%; /* Ajusta el ancho según tus preferencias */
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

/* Estilos para los botones */
.btnEliminar {
  background-color: #ff5733;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.btnEliminar:hover {
  background-color: #ff3a0c;
}
